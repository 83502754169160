import React from 'react';

import PantsProduct from '../../components/PantsProduct/PantsProduct'; 




const ProductPage = () => {
  return (
    <div>
      <br/>
      <br/>

      <PantsProduct/> 

    </div>
  );
};

export default ProductPage;
