import React from 'react';
import CustomerTable from '../../components/CustomerTable/CustomerTable'; 



const CustomerPage = () => {
  return (
    <div>
      <br/>
      <br/>
      <CustomerTable />

    </div>
  );
};

export default CustomerPage;
